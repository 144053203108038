<template>
  <b-card v-if="isLoaded">
    <b-tabs
      nav-wrapper-class="border-b"
      pills>
      <b-tab
        active
        title-link-class="rounded-pill"
        title="Ticket Detail">
        <TicketAdd
          v-if="isShowComponent"
          :ticket-id="ticket && ticket.id ? ticket.id : ''"
          :initial-ticket.sync="ticket"
          :ticket-value.sync="ticket"
          :all-ref="allRef"
          :initial-resolve-date.sync="resolveDate"
          :initial-due-date.sync="dueDate"
          :tab-index="tabIndex"
          @add-ticket="addTicket($event)"
          @save-and-closed="addTicketAndClosed($event)"
          @selected-category="getResolveAndDue($event)"
          @selectd-user="selectUser($event)"
        />
      </b-tab>
      <b-tab
        title-link-class="rounded-pill"
        :title="`Customer Profile ${countProfileAndContact > 0 ? `(${countProfileAndContact})` : ''}`"
      >
        <TicketProfile
          :all-ref="allRef"
          :customer-profile="customerProfile"
          :customer-ani="ticket.contactPhoneNo"
          :customer-items.sync="customerProfileItems"
          :ticket="ticket"
          @search-customer="getCustomerProfileByAni($event)"
          @search-customer-id="getCustomerProfileById($event)"
          @search-name-customer="getCustomerProfileByName($event)"
          @select-customer="selectCustomer($event)"
          @select-customer-and-contact="selectCustomerAndContact($event)"
          @clear-customer-and-contact="clearCustomerAndContact()"
          @reset-form="resetFormTicketProfile()"
          @update-count-customer-profile="countProfileAndContact = $event"
        />
      </b-tab>
      <b-tab
        v-if="ticket && ticket.customerProfileId"
        title-link-class="rounded-pill"
        title="Ticket list">
        <CustomerHistoryTicketTable
          v-if="isReloadTabs"
          :customer-profile-id="ticket.customerProfileId"
          :current-ticket-id="ticket.id"
          @switch-tab="switchTab($event)"
        />
        <!-- <b-card-text>
        </b-card-text> -->
      </b-tab>
      <!-- <b-tab
        title-link-class="rounded-pill"
        title="Email">
        <TicketEmail
          v-if="ticket.id"
          :ticket-id="ticket.id"
          class="mt-4"
        />
      </b-tab> -->
      <b-tab
        v-for="iframe in iframeTabs"
        :key="iframe.title"
        title-link-class="rounded-pill"
        :title="iframe.title">
        <iframe
          :title="iframe.title"
          :src="iframe.src"
          :width="iframe.width"
          :height="iframe.height"
          frameborder="0"
          style="height: 100vh;"
        />
      </b-tab>
      <!-- <b-tab
        title-link-class="rounded-pill"
        title="Invoice">
        <b-card-text>
        </b-card-text>
      </b-tab> -->
      <!-- <template>
      </template> -->
    </b-tabs>
  </b-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import dayjs from 'dayjs'
import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue'
import CustomerProvider from '@/resources/CustomerProvider'
import TicketProvider from '@/resources/TicketProvider'
// import ContactChannleProvider from '@/resources/ContactChannelProvider'
// import CallCategoryProvider from '@/resources/CallCategoryProvider'
import CategoriesProvider from '@/resources/CategoriesProvider'
// import TicketTypeProvider from '@/resources/TicketType'
// import PlantProvider from '@/resources/PlantProvider'
import UploadService from '@/resources/UploadProvider'
import resizeBase64ImagesInHTML from '@/utils/resize-image-base-64-html'
import TicketAdd from './TicketAdd.vue'
import TicketProfile from './TicketProfile.vue'
import CustomerHistoryTicketTable from './CustomerHistoryTicketTable.vue'

const TicketService = new TicketProvider()
const CustomerSerivce = new CustomerProvider()
// const ContactChannelService = new ContactChannleProvider()
// const CallCategoryServie = new CallCategoryProvider()
const CategoriesService = new CategoriesProvider()
// const TicketTypeService = new TicketTypeProvider()
// const PlantService = new PlantProvider()

export default {
  components: {
    TicketAdd,
    TicketProfile,
    CustomerHistoryTicketTable
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    tabIndex: {
      type: Number,
      required: true
    },
    allRef: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      isShowComponent: true,
      isLoaded: false,
      isReloadTabs: true,
      customerProfileItems: [],
      countProfileAndContact: 0,
      initialCustomerProfile: {
        id: '',
        customerId: '',
        name: '',
        customerTypeId: '',
        customerGroupId: null,
        sapSoldToCode: '',
        sapShipToCode: '',
        refCode1: '',
        refCode2: '',
        refCode3: '',
        companyAddress: '',
        companySubDistrict: '',
        companyDistrict: '',
        companyProvince: '',
        companyZipCode: '',
        companyOfficePhone: '',
        companyMobilePhone: '',
        companyEmail: '',
        companyGroupId: null,
        companyDepartmentId: null,
        companyCenterId: null,
        remark1: '',
        remark2: '',
        remark3: '',
        queueAgent: 1
      },
      customerProfile: {
        id: '',
        customerId: '',
        name: '',
        customerTypeId: '',
        customerGroupId: null,
        sapSoldToCode: '',
        sapShipToCode: '',
        refCode1: '',
        refCode2: '',
        refCode3: '',
        companyAddress: '',
        companySubDistrict: '',
        companyDistrict: '',
        companyProvince: '',
        companyZipCode: '',
        companyOfficePhone: '',
        companyMobilePhone: '',
        companyEmail: '',
        companyGroupId: null,
        companyDepartmentId: null,
        companyCenterId: null,
        remark1: '',
        remark2: '',
        remark3: '',
        queueAgent: 1
      },
      ticket: {
        code: '',
        ticketTypeId: null,
        contactChannelId: null,
        callCategoryId: null,
        priority: '',
        customerName: '',
        contactName: '',
        contactPhoneNo: '',
        customerProfileId: null,
        categoryLv1: null,
        categoryLv2: null,
        categoryLv3: null,
        categoryLv4: null,
        categoryLv5: null,
        categoryLv6: null,
        plantId: '',
        qty: '',
        time: '',
        subject: '',
        ticketCause: '',
        ticketResolve: '',
        ticketDetail: '',
        progressBy: {},
        dueDate: null,
        resolveDate: null,
        remark1: '',
        remark2: '',
        remark3: '',
        ticketStatus: '',
        createdAt: ''
      },
      progressBy: {},
      resolveDate: '',
      dueDate: ''
    }
  },
  computed: {
    ...mapGetters({
      // map `this.doneCount` to `this.$store.getters.doneTodosCount`
      profile: 'User/user'
    }),
    iframeTabs () {
      return [
        {
          title: 'Data View 1',
          src: 'https://csc-report.boonrawd.co.th/LastInvoice?custCode=null',
          width: '100%',
          height: '100%'
        },
        {
          title: 'Data View 2',
          src: 'https://csc-report.boonrawd.co.th/LastInvoiceSml?custCode=null&agentCode=null',
          width: '100%',
          height: '100%'
        },
        {
          title: 'Data View 3',
          src: 'https://csc-report.boonrawd.co.th/CumulativeSales?custCode=null',
          width: '100%',
          height: '100%'
        },
        {
          title: 'Data View 4',
          src: ' https://csc-report.boonrawd.co.th/CumulativeSalesSml?custCode=null&agentCode=null',
          width: '100%',
          height: '100%'
        }
      ]
    }
  },
  async mounted () {
    // await this.getTicketById()
    try {
      this.$store.dispatch('activity/increaseLoading')
      this.isShowComponent = false
      await this.setInitialTicketAndCustomer(this?.item)
      // await this.getAllOptions()
      this.progressBy = {
        id: this.profile.id,
        username: this.profile.username,
        firstName: this.profile.firstName,
        lastName: this.profile.lastName,
        userGroupId: this.profile.userGroupId,
        userTeamId: this.profile.userTeamId,
        role: this.profile.role
      }
    } catch (error) {
      console.error(error)
    } finally {
      setTimeout(() => {
        this.isLoaded = true
        this.isShowComponent = true
        this.$store.dispatch('activity/decreaseLoading')
      }, 900)
    }
  },
  methods: {
    ...mapActions({
      deleteTicket: 'ticket/DELETE_TICKET'
      // overwriteTicket: 'ticket/OVERWRITE_TICKET'
    }),
    // async getTicketById () {
    //   try {
    //     const { data } = await TicketService.findOne(this.ticketId)
    //     console.log(data)
    //     const ticket = { ...data }
    //     this.overwriteTicket({ index: this.tabIndex, payload: ticket })
    //     this.ticket.attachFiles = JSON.parse(this.ticket.attachFiles)
    //   } catch (error) {
    //     console.error(error)
    //   }
    // },
    async getCustomerProfileByAni (ani) {
      try {
        if (ani) {
          this.$store.dispatch('activity/increaseLoading')
          const aniValue = this.$replacePhoneNumber(ani, '+66')
          const { data } = await CustomerSerivce.findByAni(aniValue)
          if (data) {
            this.setIntialData(data)
            this.ticket.customerName = data.name
            this.ticket.contactName = data.name
            this.ticket.contactPhoneNo = this.$replacePhoneNumber(data.ani || data.companyMobilePhone || data.companyOfficePhone, '+66')
          }
        }
      } catch (error) {
        console.error(error)
      } finally {
        this.$store.dispatch('activity/decreaseLoading')
      }
    },
    async getCustomerProfileById (id) {
      try {
        if (id) {
          this.$store.dispatch('activity/increaseLoading')
          const { data } = await CustomerSerivce.findOne(id)
          if (data) {
            this.setIntialData(data)
            this.ticket.customerName = data.name
            this.ticket.contactName = data.name
            this.ticket.contactPhoneNo = this.$replacePhoneNumber(data.ani || data.companyMobilePhone || data.companyOfficePhone, '+66')
          }
        }
      } catch (error) {
        console.error(error)
      } finally {
        this.$store.dispatch('activity/decreaseLoading')
      }
    },
    async getCustomerProfileByName (name) {
      try {
        this.$store.dispatch('activity/increaseLoading')
        const { data } = await CustomerSerivce.paginate(1, 10, { searchCustomerName: name })
        if (data) {
          this.customerProfileItems = data
        }
      } catch (error) {
        console.error(error)
      } finally {
        this.$store.dispatch('activity/decreaseLoading')
      }
    },
    // async getAllOptions () {
    //   try {
    //     this.$store.dispatch('activity/increaseLoading')
    //     this.isShowComponent = false
    //     const ticketType = await TicketTypeService.findAllType()
    //     const contactChannel = await ContactChannelService.findAllChannel()
    //     const callCategory = await CallCategoryServie.findAllCallCategory()
    //     const categories = await CategoriesService.findAll()
    //     const plant = await PlantService.findAllPlant()
    //     this.allRef.ticketType = ticketType.data
    //     this.allRef.contactChannel = contactChannel.data
    //     this.allRef.callCategory = callCategory.data
    //     this.allRef.categories = categories.data
    //     this.allRef.plant = plant.data.map((item) => ({ ...item, code: `${item.code} - ${item.name}` }))
    //   } catch (error) {
    //     console.error(error)
    //   } finally {
    //     this.isShowComponent = true
    //     this.$store.dispatch('activity/decreaseLoading')
    //   }
    // },
    async addTicket (val) {
      try {
        this.$store.dispatch('activity/increaseLoading')
        const ticketData = val
        if (ticketData?.files?.length > 0) {
          const attachFiles = await this.uploadFile(ticketData?.files)
          if (attachFiles && attachFiles?.length > 0) {
            ticketData.attachFiles = JSON.stringify(attachFiles)
          }
        } else {
          ticketData.attachFiles = '[]'
        }

        const payload = {
          ...ticketData,
          progressBy: this.progressBy,
          customerProfileId: this.customerProfile?.id || null
        }
        delete payload.code
        delete payload.id
        delete payload.createdAt
        delete payload.createdBy
        delete payload.updatedAt
        delete payload.updatedBy
        delete payload.status
        delete payload.ani
        delete payload.files

        payload.ticketDetail = resizeBase64ImagesInHTML(payload.ticketDetail)
        payload.ticketResolve = resizeBase64ImagesInHTML(payload.ticketResolve)
        payload.contactPhoneNo = this.$replacePhoneNumber(payload?.contactPhoneNo, '+66')
        await TicketService.updateGroup(ticketData?.id, payload)
        // this.deleteTicket(this.tabIndex)
        // this.overwriteTicket({ index: this.tabIndex, payload: data })
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Ticket Add Successfully',
            icon: 'EditIcon',
            variant: 'success',
            text: '👋 Ticket has been added'
          }
        })
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `Ticket Add Fail ${error}`,
            icon: 'EditIcon',
            variant: 'danger',
            text: '👋 Ticket has been failed'
          }
        })
      } finally {
        this.$store.dispatch('activity/decreaseLoading')
      }
    },
    async addTicketAndClosed (val) {
      try {
        this.$store.dispatch('activity/increaseLoading')
        const ticketData = val
        if (ticketData?.files?.length > 0) {
          const attachFiles = await this.uploadFile(ticketData?.files)
          if (attachFiles && attachFiles?.length > 0) {
            ticketData.attachFiles = JSON.stringify(attachFiles)
          }
        } else {
          ticketData.attachFiles = '[]'
        }
        const payload = {
          ...ticketData,
          progressBy: this.progressBy,
          customerProfileId: this.customerProfile.id || null
        }

        delete payload.code
        delete payload.id
        delete payload.createdAt
        delete payload.createdBy
        delete payload.updatedAt
        delete payload.updatedBy
        delete payload.status
        delete payload.ani
        delete payload.files
        payload.ticketStatus = 'closed'

        payload.ticketDetail = resizeBase64ImagesInHTML(payload.ticketDetail)
        payload.ticketResolve = resizeBase64ImagesInHTML(payload.ticketResolve)
        payload.contactPhoneNo = this.$replacePhoneNumber(payload.contactPhoneNo, '+66')
        await TicketService.updateGroup(ticketData?.id, payload)
        // this.deleteTicket(this.tabIndex)
        // this.overwriteTicket({ index: this.tabIndex, payload: data })
        this.deleteTicket(this.tabIndex)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Ticket Add Successfully',
            icon: 'EditIcon',
            variant: 'success',
            text: '👋 Ticket has been added'
          }
        })
        this.$router.push('/ticket')
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `Ticket Add Fail ${error}`,
            icon: 'EditIcon',
            variant: 'danger',
            text: '👋 Ticket has been failed'
          }
        })
      } finally {
        this.$store.dispatch('activity/decreaseLoading')
      }
    },
    async getResolveAndDue (val) {
      try {
        this.$store.dispatch('activity/increaseLoading')
        const { data } = await CategoriesService.findOne(val)
        if (data) {
          this.ticket.priority = data?.priority
          const calculateDate = dayjs()
            .add(data.resolvedDay, 'd')
            .add(data.resolvedHour, 'h')
            .add(data.resolvedMin, 'm')
            .format('DD MMMM BBBB HH:mm:ss')
          this.resolveDate = calculateDate
          this.dueDate = calculateDate
        }
      } catch (error) {
        console.error(error)
        this.resolveDate = ''
        this.dueDate = ''
      } finally {
        this.$store.dispatch('activity/decreaseLoading')
      }
    },
    async uploadFile (files) {
      try {
        this.$store.dispatch('activity/increaseLoading')
        if (files && files.length > 0 && typeof files === 'object') {
          const arr = []
          for (const item of files) {
            const formData = new FormData()
            formData.append('file', item.file)
            // eslint-disable-next-line no-await-in-loop
            const { data: { data } } = await UploadService.upload(formData)
            arr.push({ name: item.name, url: data?.publicUrl || item.url })
          }
          return arr
        }
        return []
      } catch (error) {
        console.error(error)
        return []
      } finally {
        this.$store.dispatch('activity/decreaseLoading')
      }
    },
    setIntialData (val) {
      this.customerProfile = { ...val }
    },
    async setInitialTicketAndCustomer (data) {
      try {
        this.$store.dispatch('activity/increaseLoading')
        if (data?.id) {
          const id = data?.id
          const ticket = await TicketService.findOne(id)
          this.ticket = ticket?.data
          if (this.ticket) {
            await this.getCustomerByOne(this.ticket?.customerProfileId)
          }
          this.$store.dispatch('ticket/OVERWRITE_TICKET', {
            index: this.tabIndex,
            payload: ticket?.data
          })
        }
      } catch (error) {
        console.error(error)
      } finally {
        this.$store.dispatch('activity/decreaseLoading')
      }
    },
    async getCustomerByOne (customerId) {
      try {
        if (customerId) {
          this.$store.dispatch('activity/increaseLoading')
          const { data } = await CustomerSerivce.findOne(customerId)
          if (data) {
            this.customerProfile = data
          }
        }
      } catch (error) {
        console.error(error)
      } finally {
        this.$store.dispatch('activity/decreaseLoading')
      }
    },
    selectCustomer (val) {
      if (val && val.id) {
        this.customerProfile = val
        this.customerProfile.id = val.id
        this.setIntialData(val)
        this.ticket.customerName = val.name
        this.ticket.contactName = val.name
        this.ticket.contactPhoneNo = val.ani || val.companyMobilePhone || val.companyOfficePhone
      } else {
        this.resetFormTicketProfile()
      }
    },
    selectCustomerAndContact (val) {
      this.isReloadTabs = false
      if (val && val.id) {
        this.setIntialData(val)
        this.customerProfile = val || ''
        this.customerProfile.id = val.id || 0
        this.ticket.customerName = val?.name || ''
        this.ticket.contactName = val?.contactName || val?.name || ''
        this.ticket.customerProfileId = val.id || ''
        this.ticket.contactPhoneNo = val?.ani || val?.companyMobilePhone || val?.companyOfficePhone || val?.contactPhoneNo || val?.contactPhoneNo2 || ''
      }
      setTimeout(() => {
        this.isReloadTabs = true
      }, 1000)
    },
    clearCustomerAndContact () {
      this.isReloadTabs = false
      this.customerProfile = null
      this.ticket.customerName = ''
      this.ticket.contactName = ''
      this.ticket.customerProfileId = null
      this.ticket.contactPhoneNo = ''
      setTimeout(() => {
        this.isReloadTabs = true
      }, 1000)
    },
    selectUser (val) {
      this.progressBy = val
    },
    resetFormTicketProfile () {
      this.customerProfile = { ...this.initialCustomerProfiles }
    },
    switchTab (index) {
      this.$emit('switch-tab', index)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
